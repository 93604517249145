
import { Component, Vue } from 'vue-property-decorator'
import { apiChannelProvideGradeList, apiChannelProvideGradeDel } from '@/api/channel_provider'
import { PageMode } from '@/utils/type'
import { RequestPaging } from '@/utils/util'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
  components: {
      LsDialog,
      LsPagination
  }
})
export default class MemberGrade extends Vue {
  /** S Data **/
  // 分页请求
  pager: RequestPaging = new RequestPaging()
  /** E Data **/

  /** S Methods **/
  // 获取渠道商等级列表
  getChannelProvideGradeList() {
      this.pager
          .request({
              callback: apiChannelProvideGradeList,
              params: {}
          })
          .catch(() => {
              this.$message.error('数据请求失败，刷新重载!')
          })
  }
  // 新增渠道商等级
  onChannelProvideGradeAdd() {
      this.$router.push({
          path: '/channel_provider_grade/edit',
          query: {
              mode: PageMode.ADD
          }
      })
  }
  // 编辑渠道商等级
  onChannelProvideGradeEdit(item: any) {
      this.$router.push({
          path: '/channel_provider_grade/edit',
          query: {
              mode: PageMode.EDIT,
              id: item.id,
              level: item.rank
          }
      })
  }

  // 查看渠道商等级
  onChannelProvideGradeSee(item: any) {
      this.$router.push({
          path: '/channel_provider_grade/edit',
          query: {
              mode: PageMode.EDIT,
              id: item.id,
              level: item.rank,
              disabled: 'true'
          }
      })
  }

  // 删除渠道商等级
  onChannelProvideGradeDel(item: any) {
      apiChannelProvideGradeDel({
          id: item.id as number
      })
          .then(() => {
              this.getChannelProvideGradeList()
              this.$message.success('删除成功!')
          })
          .catch(() => {
              this.$message.error('删除失败')
          })
  }
  /** E Methods **/
  /** S Life Cycle **/
  created() {
      this.getChannelProvideGradeList()
  }

  /** E Life Cycle **/
}
